/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const List1 = ({children}) => (
  <ul css={css`
    padding: 0 0 0 22px;
    margin: 0;
    list-style: bullet;
    margin-bottom: 30px;
  `}>
    {children}
  </ul>
)

export default List1