/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Item1 = ({children}) => (
  <li css={css`
    font-family: 'Quicksand';
    font-weight: 300;
    margin-bottom: 30px;
    color: #828282;
    font-size: 1.25em;
  `}>
    {children}
  </li>
)

export default Item1