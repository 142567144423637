import React from 'react'
import styled from '@emotion/styled'

// Styles
const H2 = styled.h2`
  color: #70926E;
  font-family: 'Poiret One';
  font-size: 3em;
  margin-top: 0;
`

const Header2 = ({children}) => (
  <H2>{children}</H2>
)

export default Header2