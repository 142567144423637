/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const List2 = ({children, start='1'}) => (
  <ol 
    start={start}
    css={css`
    padding: 0 0 0 22px;
    margin: 0;
    list-style: number;
    margin-bottom: 30px;
  `}>
    {children}
  </ol>
)

export default List2